import { FormEvent } from 'react';
import { Field, Form, ValidatedFormValue } from '@magical-forms/react-next';
type FieldFromFieldInstance<TForm extends Form<Field>> = TForm['_field'];

function getTouchedState(field: Field, state: any): any {
  if (field.kind === 'object') {
    let obj: any = {};
    for (let key in field.fields) {
      obj[key] = getTouchedState(field.fields[key], state[key]);
    }
    return obj;
  }
  if (field.kind === 'array') {
    return (state as any[]).map((element) =>
      getTouchedState(field.element, element)
    );
  }
  if (state.touched) {
    return state;
  }
  return { ...state, touched: true };
}

export function useSubmit<TForm extends Form<Field>>(
  form: TForm,
  onSubmit: (form: ValidatedFormValue<FieldFromFieldInstance<TForm>>) => void
) {
  return (event?: FormEvent<HTMLFormElement>) => {
    if (event) {
      if (event.defaultPrevented) {
        return;
      }
      event.preventDefault();
    }
    if (form.validity === 'valid') {
      onSubmit(form.value);
    } else {
      form.setState(getTouchedState(form._field, form.state));
    }
  };
}
