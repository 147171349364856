import * as fields from './fields';
export { useFormSnapshot } from './useFormSnapshot';
export { formStateToFormValue } from './formStateToFormValue';
export { useSubmit } from './useSubmit';
export { isFormValueEqualToValue } from './isFormValueEqualToValue';
export { useIsFormEqualToInitialValue } from './useIsFormEqualToInitialValue';
export { countVisibleValidationErrors } from './countVisibleValidationErrors';

export const { scalar, ...field } = fields;

export {
  resetForm,
  useForm,
  validation,
  getInitialState,
} from '@magical-forms/react-next';

export type {
  Field,
  Form,
  FormState,
  FormValue,
  ValidatedFormValue,
  InitialValueInput,
} from '@magical-forms/react-next';

export { validate } from './validate';
