import React from 'react';

import { useTheme } from '@reckon-web/theme';
import { DisallowStyleProps } from '@reckon-web/utils';

import { UseToneColorProps, useToneColor } from './useToneColor';

export type IndicatorDotProps = {
  /**
   * When the intent isn't provided by text, you must supply an "aria-label" for
   * assistive tech users.
   */
  'aria-label'?: string;
} & DisallowStyleProps &
  UseToneColorProps;

/** A small decorative indicator used to call attention to an item. */
export const IndicatorDot = ({
  'aria-label': ariaLabel,
  tone,
}: IndicatorDotProps) => {
  const { spacing } = useTheme();
  const backgroundColor = useToneColor({ tone });
  const size = spacing.small;

  return (
    <div
      aria-label={ariaLabel}
      style={{
        backgroundColor,
        borderRadius: size,
        height: size,
        width: size,
      }}
    />
  );
};
