import React from 'react';

import { AlertDialog } from '@reckon-web/modal-dialog';
import { Text } from '@reckon-web/text';

type Props = {
  isOpen: boolean;
  id: string | undefined;
  onCancelConfirmation: () => void;
  onClose: () => void;
};

export const CancelConfirmationDialog = (props: Props) => {
  return (
    <AlertDialog
      isOpen={props.isOpen}
      id={props.id}
      title="Discard changes?"
      tone="critical"
      actions={{
        confirm: {
          label: 'Yes, discard',
          action: () => {
            props.onClose();
            props.onCancelConfirmation();
          },
        },
        cancel: {
          label: 'Continue editing',
          action: props.onClose,
        },
      }}
    >
      <Text>Changes you've made so far will not be saved.</Text>
    </AlertDialog>
  );
};
