/** @jsx jsx */

import { Button } from '@reckon-web/button';
import { jsx } from '@reckon-web/core';
import { Box } from '@reckon-web/box';
import { Heading } from '@reckon-web/heading';
import { useTheme } from '@reckon-web/theme';
import { makeId, useId } from '@reckon-web/utils';

import { DialogBase } from './DialogBase';

type Action = {
  action: () => void;
  label: string;
};

type AlertDialogProps = {
  actions: {
    block?: boolean /** make action buttons full width */;
    cancel?: Action;
    confirm: Action & { loading?: boolean };
  };
  id?: string;
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  tone?: 'critical' | 'active';
};

export const AlertDialog = ({
  actions,
  isOpen,
  children,
  title,
  id,
  tone = 'active',
}: AlertDialogProps) => {
  const { cancel, confirm } = actions;
  const theme = useTheme();
  const instanceId = useId(id);
  const headingId = makeId(instanceId, 'heading');

  // call the "cancel" action on `Esc` key press (if it exists), or the "confirm" action if there is no "cancel" action.
  const onClose = () => {
    if (actions.cancel) {
      actions.cancel.action();
    } else {
      actions.confirm.action();
    }
  };

  return (
    <DialogBase
      id={instanceId}
      isOpen={isOpen}
      onClose={onClose}
      width={440}
      aria-labelledby={headingId}
    >
      <div css={{ padding: theme.spacing.xlarge }}>
        <Heading id={headingId} level="4">
          {title}
        </Heading>

        <Box marginY="large">{children}</Box>

        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            flexDirection: actions.block ? 'column-reverse' : 'row',
          }}
        >
          {cancel && (
            <Button
              id={makeId(instanceId, 'cancel')}
              disabled={confirm.loading}
              key={cancel.label}
              label={cancel.label}
              onClick={cancel.action}
              weight="none"
              tone="passive"
              size="small"
              block={actions.block}
            />
          )}
          <Button
            id={makeId(instanceId, 'confirm')}
            css={{
              marginLeft: actions.block ? 0 : theme.spacing.medium,
              marginBottom: actions.block ? theme.spacing.medium : 0,
            }}
            key={confirm.label}
            label={confirm.label}
            loading={confirm.loading}
            onClick={confirm.action}
            tone={tone}
            size="small"
            block={actions.block}
          />
        </div>
      </div>
    </DialogBase>
  );
};
