import { useMemo, useState } from 'react';
import { merge } from 'lodash';
import { Field, Form, FormValue, resetForm } from '@magical-forms/react-next';
import isArray from 'lodash/isArray';

import { areFieldsEqual } from './areFieldsEqual';

type Options = {
  /**
   * Flag to delay creating a snapshot when the component mounts.
   * If your data is not ready on mount then set this to true to manually control when your data will be ready.
   * If you have data ready to populate the form then you can leave this as default it will create the snapshot on mount.
   */
  deferSnapshot?: boolean;
};

export const useFormSnapshot = <TField extends Field>(
  form: Form<TField>,
  options?: Options
) => {
  const [snapshot, setSnapshot] = useState<FormValue<TField>>(
    options?.deferSnapshot ? undefined : form.value
  );

  /**
   *
   * @param value: If you want to snapshot immediately after form changes provide the new value here
   */
  const takeSnapshot = (value?: FormValue<TField>) => {
    if (isArray(value)) {
      setSnapshot(merge([], value || form.value));
    } else {
      setSnapshot(merge({}, form.value, value));
    }
  };

  const isDirty = useMemo(() => {
    return !areFieldsEqual(form._field, form.value, snapshot);
  }, [form, snapshot]);
  /**
   * @description: Resets form values to the current snapshot
   */
  const rollback = () => {
    if (snapshot) {
      // @ts-ignore Typing looks correct. Need to ask Mitchelle.
      resetForm(form, snapshot);
    }
  };

  return {
    takeSnapshot,
    isDirty,
    rollback,
    snapshot,
  };
};
