import { ChangeEvent } from 'react';
import { scalar } from '@magical-forms/react-next';

export { object, scalar, array } from '@magical-forms/react-next';

function validationToFieldProps<
  Obj extends {
    validity: 'valid' | 'invalid';
    error?: string;
    touched: boolean;
  }
>({
  error,
  validity,
  touched,
  ...obj
}: Obj): Omit<Obj, 'error' | 'validity' | 'touched'> & {
  invalidMessage?: string;
} {
  if (touched) {
    return {
      invalidMessage: error,
      ...obj,
    };
  }
  return obj;
}

export const multiselect = scalar({
  props: validationToFieldProps,
  initialValue: (input: string[] | undefined) => input || [],
});

export const text = scalar({
  props: ({ onChange, ...field }) => ({
    ...validationToFieldProps(field),
    onChange(event: ChangeEvent<HTMLInputElement>) {
      onChange(event.target.value);
    },
  }),
  initialValue: (input: string | undefined | null) => input || '',
});

export const multilineText = scalar({
  props: ({ onChange, ...field }) => ({
    ...validationToFieldProps(field),
    onChange(event: ChangeEvent<HTMLTextAreaElement>) {
      onChange(event.target.value);
    },
  }),
  initialValue: (input: string | undefined | null) => input || '',
});

export const email = scalar({
  props: ({ onChange, ...field }) => ({
    ...validationToFieldProps(field),
    inputMode: 'email' as const,
    onChange(event: ChangeEvent<HTMLInputElement>) {
      onChange(event.target.value);
    },
  }),
  initialValue: (input: string | undefined | null) => input || '',
});

// export const number = scalar({
//   props: ({ onChange, validity, error, value, ...field }) => ({
//     ...field,
//     value: value === undefined ? '' : '' + value,
//     invalidMessage: error,
//     onChange(event: ChangeEvent<HTMLInputElement>) {
//       onChange(
//         event.target.value === '' ? undefined : Number(event.target.value)
//       );
//     },
//   }),
//   initialValue: (input: number | undefined) => input,
// });

export const currency = scalar({
  props: validationToFieldProps,
  initialValue: (input: string | number | undefined | null) => input || '',
});

export const boolean = scalar({
  props: validationToFieldProps,
  initialValue: (input: boolean | undefined | null) => input || false,
});

export const string = scalar({
  props: validationToFieldProps,
  initialValue: (input: string | undefined | null) => input ?? undefined,
});

type ISODate = string & { ___ISODateString: true };

type ISODateRange = { start: ISODate; end: ISODate };

export const date = scalar({
  props: (input) => ({
    ...validationToFieldProps(input),
    onClear() {
      input.onChange(undefined);
    },
  }),
  initialValue: (input: ISODate | undefined | null) => input ?? undefined,
});

export const dateRange = scalar({
  props: validationToFieldProps,
  initialValue: (input: ISODateRange | undefined | null) => input ?? undefined,
});

export const empty = scalar({
  props: () => undefined,
  initialValue: (input: undefined) => input,
});

export const select = <Value extends string>() =>
  scalar({
    props: validationToFieldProps,
    initialValue: (input: Value | undefined | null) => input ?? undefined,
  });

export const autocomplete = <
  Option extends { label: string; value: string }
>() =>
  scalar({
    props: validationToFieldProps,
    initialValue: (input: Option | undefined | null) => input ?? null,
  });
