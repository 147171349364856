/** @jsx jsx */
import { ReactElement, ReactNode } from 'react';

import { Button } from '@reckon-web/button';
import { jsx } from '@reckon-web/core';
import { Heading } from '@reckon-web/heading';
import { Inline } from '@reckon-web/inline';
import { Stack } from '@reckon-web/stack';
import { forwardRefWithAs, makeId, useId } from '@reckon-web/utils';

import { DialogBase } from './DialogBase';

type Action = {
  action: () => void;
  label: string;
};

type InfoDialogProps = {
  actions: {
    cancel?: Action;
    confirm: Action & { loading?: boolean };
  };
  /** Use any of our spot or hero illustrations here. */
  illustration: ReactElement;
  children: ReactNode;
  title: string;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const InfoDialog = forwardRefWithAs<'div', InfoDialogProps>(
  (
    { actions, children, illustration, title, id, isOpen, onClose, ...props },
    ref
  ) => {
    const { cancel, confirm } = actions;
    const instanceId = useId(id);
    const headingId = makeId(instanceId, 'heading');
    const width = 480;

    return (
      <DialogBase
        id={instanceId}
        isOpen={isOpen}
        onClose={onClose}
        width={width}
        ref={ref}
        aria-labelledby={headingId}
        {...props}
      >
        <Stack
          gap="xlarge"
          align="center"
          padding="xlarge"
          css={{
            textAlign: 'center',
          }}
        >
          {illustration}
          <Stack gap="small">
            <Heading level="3" as="h2" id={headingId}>
              {title}
            </Heading>
            {children}
          </Stack>

          <Inline gap="small">
            {cancel && (
              <Button
                id={makeId(instanceId, 'cancel')}
                disabled={confirm.loading}
                key={cancel.label}
                label={cancel.label}
                onClick={cancel.action}
                weight="none"
                tone="passive"
                size="small"
              />
            )}
            <Button
              id={makeId(instanceId, 'confirm')}
              key={confirm.label}
              label={confirm.label}
              loading={confirm.loading}
              onClick={confirm.action}
              size="small"
            />
          </Inline>
        </Stack>
      </DialogBase>
    );
  }
);
