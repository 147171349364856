/** @jsx jsx */

import { forwardRef } from 'react';

import { jsx } from '@reckon-web/core';
import { Field, FieldProps } from '@reckon-web/field';
import { TextInput, TextInputProps } from '@reckon-web/text-input';

export type TextFieldProps = FieldProps & TextInputProps;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      description,
      helpText,
      id,
      invalidMessage,
      label,
      labelVisible,
      reserveMessageSpace,
      ...inputProps
    },
    ref
  ) {
    return (
      <Field
        description={description}
        helpText={helpText}
        id={id}
        invalidMessage={invalidMessage}
        label={label}
        labelVisible={labelVisible}
        reserveMessageSpace={reserveMessageSpace}
      >
        <TextInput ref={ref} {...inputProps} />
      </Field>
    );
  }
);
