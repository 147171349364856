/** @jsx jsx */

import { ReactElement } from 'react';

import { jsx } from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';
import { forwardRefWithAs, useId } from '@reckon-web/utils';

import { DialogBase } from './DialogBase';

const SIZES = {
  small: 480,
  medium: 620,
  large: 1140,
  full: '95%',
};

type ContentDialogProps = {
  children: React.ReactNode;
  footer?: ReactElement;
  header?: ReactElement;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  size?: keyof typeof SIZES;
};

/** @deprecated The ContentDialog component has been deprecated and will be removed in future versions. Use the SideDrawer package instead.  */
export const ContentDialog = forwardRefWithAs<'div', ContentDialogProps>(
  (
    { children, footer, header, id, isOpen, onClose, size = 'large', ...props },
    ref
  ) => {
    const theme = useTheme();
    const instanceId = useId(id);

    const width = SIZES[size];
    const borderStyle = `1px solid ${theme.palette.global.border}`;

    return (
      <DialogBase
        id={instanceId}
        isOpen={isOpen}
        onClose={onClose}
        width={width}
        ref={ref}
        enforceLayout
        {...props}
      >
        {header && <div css={{ borderBottom: borderStyle }}>{header}</div>}

        <div css={{ flex: 1, overflowY: 'auto' }}> {children}</div>

        {footer && <div css={{ borderTop: borderStyle }}>{footer}</div>}
      </DialogBase>
    );
  }
);
