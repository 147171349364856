/** @jsx jsx */

import {
  Fragment,
  HTMLAttributes,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import throttle from 'lodash.throttle';

import { Button } from '@reckon-web/button';
import { jsx } from '@reckon-web/core';
import { Heading } from '@reckon-web/heading';
import { useTheme } from '@reckon-web/theme';
import { hexToRgb, makeId, useId } from '@reckon-web/utils';

import { CancelConfirmationDialog } from './CancelConfirmationDialog';
import { DrawerBase } from './DrawerBase';
import { useDrawerControllerContext } from './DrawerController';
import { OptionalActionsType } from './types';

export type DrawerProps = {
  actions: OptionalActionsType;
  children: ReactNode;
  id?: string;
  initialFocusRef?: MutableRefObject<any>;
  type: 'form' | 'content';
  shouldShowCancelConfirmationDialog?: boolean;
} & (
  | {
      title: string;
    }
  | {
      /** Replace the header element with your own. */
      header: ReactElement;
      /** Label the dialog. */
      'aria-label': string;
    }
);

/** @deprecated The Drawer package has been deprecated. Use the SideDrawer package instead. */
export const Drawer = ({
  actions,
  children,
  id,
  initialFocusRef,
  shouldShowCancelConfirmationDialog = true,
  type,
  ...props
}: DrawerProps) => {
  const { spacing } = useTheme();
  const transitionState = useDrawerControllerContext();
  const [
    isCancelConfirmationDialogOpen,
    setIsCancelConfirmationDialogOpen,
  ] = useState(false);

  const dismissAction = actions.cancel
    ? actions.cancel.action
    : actions.confirm.action;
  const safeClose = actions.confirm.loading
    ? () => {}
    : type === 'content'
    ? dismissAction
    : shouldShowCancelConfirmationDialog
    ? () => {
        setIsCancelConfirmationDialogOpen(true);
      }
    : dismissAction;

  const instanceId = useId(id);
  const headingId = makeId(instanceId, 'heading');
  const drawerProps =
    'title' in props
      ? {
          'aria-labelledby': headingId,
        }
      : {
          'aria-label': props['aria-label'],
        };

  return (
    <DrawerBase
      id={instanceId}
      transitionState={transitionState}
      initialFocusRef={initialFocusRef}
      onSubmit={type === 'form' ? actions.confirm.action : undefined}
      onClose={safeClose}
      width="narrow"
      {...drawerProps}
    >
      {'title' in props ? (
        <div css={{ padding: `${spacing.large}px ${spacing.xlarge}px` }}>
          <Heading id={headingId} level="3">
            {props.title}
          </Heading>
        </div>
      ) : (
        props.header
      )}

      <Body>{children}</Body>

      <div
        css={{
          display: 'flex',
          flexShrink: 0,
          flexDirection: 'column',
          padding: `${spacing.large}px ${spacing.xlarge}px`,

          '> button + button': {
            marginTop: spacing.small,
          },
        }}
      >
        <Button
          {...(type === 'form'
            ? {
                type: 'submit',
              }
            : { onClick: actions.confirm.action })}
          id={makeId(instanceId, 'confirm')}
          loading={actions.confirm.loading}
          label={actions.confirm.label}
        />
        {actions.cancel && (
          <Button
            id={makeId(instanceId, 'cancel')}
            onClick={safeClose}
            disabled={actions.confirm.loading}
            weight="none"
            tone="passive"
            label={actions.cancel.label}
          />
        )}
      </div>
      <CancelConfirmationDialog
        id={makeId(instanceId, 'confirmation-dialog')}
        isOpen={isCancelConfirmationDialogOpen}
        onCancelConfirmation={dismissAction}
        onClose={() => setIsCancelConfirmationDialogOpen(false)}
      />
    </DrawerBase>
  );
};

// Styled components
// ------------------------------

type ScrollStatus = 'none' | 'top' | 'bottom' | 'between';

const getScrollStatus = (element: HTMLDivElement) => {
  const { clientHeight, scrollHeight } = element;

  if (scrollHeight === clientHeight) {
    return 'none';
  }
  if (element.scrollTop === 0) {
    return 'top';
  }
  if (element.scrollTop === scrollHeight - clientHeight) {
    return 'bottom';
  }

  return 'between';
};

const Body = (props: HTMLAttributes<HTMLDivElement>) => {
  const { spacing } = useTheme();
  const [scrollStatus, setScrollStatus] = useState<ScrollStatus>('none');
  const ref = useRef<HTMLDivElement>(null);

  // reset the status when children change; the scroll height may have changed
  useEffect(() => {
    const bodyElement = ref.current;

    if (bodyElement) {
      setScrollStatus(getScrollStatus(bodyElement));
    }
  }, [props.children]);

  // listen to scroll and update status
  // NOTE: ideally we'd also do this on `resize`, but they impact is so small that
  // i can't justify binding another listener that will affect performance
  useEffect(() => {
    const bodyElement = ref.current;

    if (bodyElement) {
      const handleScroll = throttle(() => {
        setScrollStatus(getScrollStatus(bodyElement));
      }, 200);

      bodyElement.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        handleScroll.cancel();
        bodyElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // dividers must be rendered outside the scrollable container so they don't
  // move with it. always render dividers so content doesn't jump around.
  return (
    <Fragment>
      <Divider active={['bottom', 'between'].includes(scrollStatus)} />
      <div
        ref={ref}
        css={{
          overflowY: 'auto',
          paddingLeft: spacing.xlarge,
          paddingRight: spacing.xlarge,
        }}
        {...props}
      />
      <Divider active={['top', 'between'].includes(scrollStatus)} />
    </Fragment>
  );
};

const Divider = ({ active }: { active: boolean }) => {
  const { palette, spacing } = useTheme();
  const height = 1;

  return (
    <div
      css={{
        backgroundColor: hexToRgb(palette.text.base, 0.12),
        flexShrink: 0,
        height: height,
        marginBottom: -height,
        marginLeft: spacing.xlarge,
        marginRight: spacing.xlarge,
        marginTop: -height,
        opacity: active ? 1 : 0,
        position: 'relative',
        transition: 'opacity 150ms linear',
        zIndex: 1,
      }}
    />
  );
};
