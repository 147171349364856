/** @jsx jsx */

import { HTMLAttributes, forwardRef } from 'react';

import { jsx, keyframes } from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';

const fadeInAnim = keyframes({
  from: {
    opacity: 0,
  },
});
const easing = 'cubic-bezier(0.2, 0, 0, 1)';

export type BlanketProps = { animate?: boolean } & HTMLAttributes<
  HTMLDivElement
>;

export const Blanket = forwardRef<HTMLDivElement, BlanketProps>(
  ({ animate, ...props }, ref) => {
    const theme = useTheme();
    const animationStyles = animate
      ? {
          animation: `${fadeInAnim} 320ms ${easing}`,
        }
      : null;

    return (
      <div
        ref={ref}
        css={[
          {
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // TODO get this from the theme
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: theme.elevation.modal,

            // support children
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          },
          animationStyles,
        ]}
        {...props}
      />
    );
  }
);
