/** @jsx jsx */

import {
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  forwardRef,
  useState,
} from 'react';

import { IndicatorDot } from '@reckon-web/badge';
import { Button } from '@reckon-web/button';
import { jsx } from '@reckon-web/core';
import { Heading } from '@reckon-web/heading';
import { useTheme } from '@reckon-web/theme';
import { Tooltip } from '@reckon-web/tooltip';
import { useTabListEventListeners } from '@reckon-web/tabs';
import { makeId, plural, useId } from '@reckon-web/utils';

import { CancelConfirmationDialog } from './CancelConfirmationDialog';
import { DrawerBase } from './DrawerBase';
import { ActionsType } from './types';
import { useDrawerControllerContext } from './DrawerController';

type TabType = {
  label: string;
  issueCount?: number;
  content: ReactNode;
};
type TabbedDrawerProps = {
  actions: ActionsType;
  id?: string;
  initialFocusRef?: MutableRefObject<any>;
  onTabChange: (selectedIndex: number) => void;
  selectedTabIndex: number;
  tabs: TabType[];
  title: string;
  shouldShowCancelConfirmationDialog?: boolean;
};

const noop = () => {};

export const TabbedDrawer = ({
  actions,
  id,
  initialFocusRef,
  onTabChange,
  selectedTabIndex,
  tabs,
  title,
  shouldShowCancelConfirmationDialog = true,
}: TabbedDrawerProps) => {
  const transitionState = useDrawerControllerContext();

  const { cancel, confirm } = actions;

  const [tabListElement, setTabListElement] = useState<HTMLElement | null>(
    null
  );
  useTabListEventListeners({
    selectedIndex: selectedTabIndex,
    onSelectedIndexChange: onTabChange,
    tabListElement,
    orientation: 'vertical',
  });

  const [
    isCancelConfirmationDialogOpen,
    setIsCancelConfirmationDialogOpen,
  ] = useState(false);
  const safeClose = confirm.loading
    ? noop
    : shouldShowCancelConfirmationDialog
    ? () => {
        setIsCancelConfirmationDialogOpen(true);
      }
    : actions.cancel.action;

  const instanceId = useId(id);
  const headingId = makeId(instanceId, 'drawer-heading');
  const getMeta = (index: number) => ({
    isSelected: selectedTabIndex === index,
    panelId: makeId(instanceId, 'panel', index),
    tabId: makeId(instanceId, 'tab', index),
  });

  return (
    <DrawerBase
      id={instanceId}
      transitionState={transitionState}
      aria-labelledby={headingId}
      initialFocusRef={initialFocusRef}
      onClose={safeClose}
      onSubmit={actions.confirm.action}
      width="wide"
    >
      <Header>
        <Heading id={headingId} level="3">
          {title}
        </Heading>
      </Header>

      <div css={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Sidebar ref={setTabListElement}>
          {tabs.map((tab, index) => {
            const { isSelected, panelId, tabId } = getMeta(index);

            return (
              <Tab
                aria-controls={panelId}
                key={tabId}
                id={tabId}
                isSelected={isSelected}
              >
                {tab.label}
                {tab.issueCount ? (
                  <TabIndicator issueCount={tab.issueCount} />
                ) : null}
              </Tab>
            );
          })}
        </Sidebar>
        <Content>
          {tabs.map((tab, index) => {
            const { isSelected, panelId, tabId } = getMeta(index);

            return (
              <div
                aria-labelledby={tabId}
                hidden={!isSelected}
                id={panelId}
                key={panelId}
                role="tabpanel"
              >
                {isSelected ? tab.content : null}
              </div>
            );
          })}
        </Content>
      </div>

      <Footer>
        <Button
          id={makeId(instanceId, 'cancel')}
          disabled={confirm.loading}
          label={cancel.label}
          onClick={safeClose}
          tone="passive"
          weight="none"
        />
        <Button
          id={makeId(instanceId, 'confirm')}
          type="submit"
          loading={confirm.loading}
          label={confirm.label}
        />
      </Footer>
      <CancelConfirmationDialog
        id={makeId(instanceId, 'confirmation-dialog')}
        isOpen={isCancelConfirmationDialogOpen}
        onCancelConfirmation={actions.cancel.action}
        onClose={() => setIsCancelConfirmationDialogOpen(false)}
      />
    </DrawerBase>
  );
};

// Styled Components
// ------------------------------

type GenericDivProps = HTMLAttributes<HTMLDivElement>;

const Header = (props: GenericDivProps) => {
  const { palette, spacing } = useTheme();

  return (
    <div
      css={{
        borderBottom: `1px solid ${palette.global.border}`,
        padding: `${spacing.large}px ${spacing.xlarge}px`,
      }}
      {...props}
    />
  );
};

const Footer = (props: GenericDivProps) => {
  const { palette, spacing } = useTheme();

  return (
    <div
      css={{
        borderTop: `1px solid ${palette.global.border}`,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: `${spacing.large}px ${spacing.xlarge}px`,

        '> button': {
          marginLeft: spacing.small,
        },
      }}
      {...props}
    />
  );
};

const Sidebar = forwardRef<HTMLDivElement, GenericDivProps>((props, ref) => {
  const { palette, spacing } = useTheme();

  return (
    <div
      ref={ref}
      aria-multiselectable="false"
      role="tablist"
      css={{
        backgroundColor: palette.background.muted,
        borderRight: `1px solid ${palette.global.border}`,
        overflowY: 'auto',
        padding: spacing.large,
        width: 200,
      }}
      {...props}
    />
  );
});

type TabProps = { isSelected: boolean } & HTMLAttributes<HTMLButtonElement>;
const Tab = ({ isSelected, ...props }: TabProps) => {
  const { palette, radii, spacing, typography } = useTheme();

  return (
    <button
      role="tab"
      type="button"
      aria-selected={isSelected}
      tabIndex={isSelected ? 0 : -1}
      css={{
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 0,
        borderRadius: radii.small,
        boxSizing: 'border-box',
        color: palette.listItem.text,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: typography.fontSize.small,
        fontWeight: typography.fontWeight.medium,
        padding: `${spacing.medium}px ${spacing.large}px`,
        textAlign: 'start',
        width: '100%',

        ':hover,:focus': {
          backgroundColor: palette.listItem.backgroundFocused,
          color: palette.listItem.textFocused,
        },
        '&.focus-visible': {
          outline: 'none !important',
          boxShadow: `0 0 0 2px ${palette.global.focusRing}`,
        },
        ':active': {
          backgroundColor: palette.listItem.backgroundPressed,
        },
        '&[aria-selected=true]': {
          backgroundColor: palette.listItem.backgroundSelected,
          color: palette.listItem.textSelected,
        },
      }}
      {...props}
    />
  );
};

type IndicatorProps = { issueCount: number };
const TabIndicator = ({ issueCount }: IndicatorProps) => {
  return (
    <Tooltip content={plural(issueCount, 'issue', 'issues')}>
      <div
        aria-label="This tab contains issues"
        css={{
          position: 'relative',

          // increase hit-area
          '::before': {
            content: '" "',
            position: 'absolute',
            left: -8,
            bottom: -8,
            top: -8,
            right: -8,
          },
        }}
      >
        <IndicatorDot tone="critical" />
      </div>
    </Tooltip>
  );
};

const Content = (props: GenericDivProps) => {
  const { spacing } = useTheme();

  return (
    <div
      css={{
        flex: 1,
        overflowY: 'auto',
        padding: `${spacing.large}px ${spacing.xlarge}px`,
      }}
      {...props}
    />
  );
};
